<template lang="pug">
.not-found
	h1 404
	h2 Page Not Found
</template>

<script>
export default {
	name: "NotFound"
}
</script>

<style scoped>
.not-found {
	width: 100%;
	display: flex;
	min-height: calc(100vh - 202px);
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>
